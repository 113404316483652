import {ESTIMATE_STATUS, RATE_UNIT} from '../enums';
import userMixin from '@/mixins/user.mixin';

const estimateMixin = {
    data() {
        return {
            RATE_UNIT: RATE_UNIT,
        };
    },
    mixins: [userMixin],
    computed: {
        // The estimate ID
        estimate_id() {
            if (this.estimate && this.estimate.id) return this.estimate.id;
            return this.$route.params.estimate_id;
        },
        // If the estimate is in DRAFT state
        is_estimate_draft() {
            if (!this.estimate) return false;
            return this.estimate.status === ESTIMATE_STATUS.DRAFT;
        },
        // If the estimate is PENDING (in review)
        is_estimate_pending() {
            if (!this.estimate) return false;
            return this.estimate.status === ESTIMATE_STATUS.PENDING;
        },
        // If the estimate has been SENT to the client and submitted to Xero
        is_estimate_sent() {
            if (!this.estimate) return false;
            return this.estimate.status === ESTIMATE_STATUS.SENT;
        },
        // If the estimate has been ACCEPTED
        is_estimate_accepted() {
            if (!this.estimate) return false;
            return this.estimate.status === ESTIMATE_STATUS.ACCEPTED;
        },
        // If the estimate has been DECLINED
        is_estimate_declined() {
            if (!this.estimate) return false;
            return this.estimate.status === ESTIMATE_STATUS.DECLINED;
        },
        // If the estimate is LOCKED for editing
        is_estimate_locked() {
            if (!this.estimate) return false;
            return (
                this.estimate.status === ESTIMATE_STATUS.SENT ||
                this.estimate.status === ESTIMATE_STATUS.ACCEPTED ||
                this.estimate.status === ESTIMATE_STATUS.DECLINED
            );
        },
        // If the estimate has been COMPLETED
        is_estimate_completed() {
            if (!this.estimate) return false;
            return (
                this.estimate.status === ESTIMATE_STATUS.ACCEPTED ||
                this.estimate.status === ESTIMATE_STATUS.DECLINED
            );
        },
        can_approve() {
            return this.user_is_super_admin && this.is_xero_authed;
        },
        desc_width() {
            if (this.is_estimate_draft) {
                if (this.estimate.unit === RATE_UNIT.HOUR) return 20;
                else return 18;
            } else {
                if (this.estimate.unit === RATE_UNIT.HOUR) return 17;
                else return 15;
            }
        },
        desc_header_width() {
            if (this.is_estimate_draft) {
                if (this.estimate.unit === RATE_UNIT.HOUR) return 16;
                else return 14;
            } else {
                if (this.estimate.unit === RATE_UNIT.HOUR) return 17;
                else return 15;
            }
        },
        num_width() {
            return 2;
        },
        cost_width() {
            return 3;
        },
        option_width() {
            if (!this.is_estimate_draft) {
                return 1;
            }
            return 2;
        },
    },
    methods: {
        status2TagType(status) {
            switch (status) {
                case ESTIMATE_STATUS.DRAFT:
                    return 'default';
                case ESTIMATE_STATUS.PENDING:
                    return 'warning';
                case ESTIMATE_STATUS.SENT:
                    return 'info';
                case ESTIMATE_STATUS.ACCEPTED:
                    return 'success';
                case ESTIMATE_STATUS.DECLINED:
                    return 'danger';
                default:
                    return 'info';
            }
        },
        status2Label(estimate) {
            switch (estimate.status) {
                case ESTIMATE_STATUS.DRAFT:
                    return 'Draft';
                case ESTIMATE_STATUS.PENDING:
                    return 'Pending';
                case ESTIMATE_STATUS.ACCEPTED:
                    return 'Accepted';
                case ESTIMATE_STATUS.DECLINED:
                    return 'Declined';
            }
        },
        /**
         * Returns if the given group is excluded.
         * Explicitly excluded or excluded because all the tasks are
         * @param group
         * @param tasks
         * @returns {boolean} true if the group is functionally excluded
         */
        isGroupExcluded(group, tasks) {
            // if this group is not explicitly excluded, and
            // we find any tasks that are not excluded,
            // then this group is not considered excluded
            if (group.excluded) return true;
            //Note: we consider a group excluded if there are no tasks
            return tasks.every((task) =>
                this.isTaskExcluded(task, task.subtasks)
            );
        },
        /**
         * Returns if the given task is excluded.
         * Explicitly excluded or excluded because all the subtasks are
         * @param task
         * @param subtasks
         * @returns {boolean} true if the task is excluded
         */
        isTaskExcluded(task, subtasks) {
            // if this task is not explicitly excluded, and
            // we find any subtasks that are not excluded,
            // then this task is not considered excluded
            if (task.excluded) return true;
            //Note: we do not consider a task excluded if there are no subtasks
            if (!subtasks.length) return false;
            return subtasks.every((s) => s.excluded);
        },
        /**
         * Returns if any of the given subtasks is excluded
         * @param subtasks
         * @returns {boolean} true if any subtask is excluded
         */
        isAnySubtaskExcluded(subtasks) {
            return subtasks.some((s) => s.excluded);
        },

        /**
         * Returns the total cost of the given Estimate group
         * @param group
         * @param tasks
         * @param countExcluded
         * @returns {number}
         */
        getTotalForGroup(group, tasks, countExcluded = false) {
            const groupTotal = tasks.reduce((totalTasks, task) => {
                if (
                    (countExcluded || !task.excluded) &&
                    this.$options.filters.fireRef2id(task.group) === group.id
                )
                    return (totalTasks += task.cost);

                return totalTasks;
            }, 0);

            return groupTotal * ((100 - (group.discount || 0)) / 100);
        },

        /**
         * Returns the total cost across multiple given groups
         * @param groups
         * @param tasks
         * @returns {*}
         */
        getTotalForGroups(groups, tasks) {
            return groups.reduce((totalGroups, group) => {
                if (group.excluded) return totalGroups;
                const groupTotal = this.getTotalForGroup(group, tasks);
                return (totalGroups += groupTotal);
            }, 0);
        },

        /** Get a static snapshot of an estimate and its groups, tasks and subtasks:
         * - groups appended to estimate,
         * -- tasks appended to each group,
         * --- subtasks appended to each task. */
        async getNestedEstimate(id) {
            const estimateRef = this.$fire.doc(`estimates/${id}`);

            // estimate
            const estimateSnapshot = await estimateRef.get();
            const estimate = {
                ...estimateSnapshot.data(),
                id: estimateSnapshot.id,
                groups: [],
            };

            // groups
            let groups = [];
            const groupSnapshots = await this.$fire
                .collection('estimate_groups')
                .where('estimate', '==', estimateRef)
                .get();
            groupSnapshots.forEach((snapshot) => {
                groups.push({
                    ...snapshot.data(),
                    id: snapshot.id,
                    tasks: [],
                });
            });
            groups.sort((a, b) => a.sort - b.sort);

            // tasks
            const tasks = [];
            const taskSnapshots = await this.$fire
                .collection('estimate_tasks')
                .where('estimate', '==', estimateRef)
                .get();
            taskSnapshots.forEach((snapshot) => {
                tasks.push({
                    ...snapshot.data(),
                    id: snapshot.id,
                    subtasks: [],
                });
            });
            tasks.sort((a, b) => a.sort - b.sort);

            // subtasks
            const subtasks = [];
            const subtaskSnapshots = await this.$fire
                .collection('estimate_subtasks')
                .where('estimate', '==', estimateRef)
                .get();
            subtaskSnapshots.forEach((snapshot) => {
                subtasks.push({
                    ...snapshot.data(),
                    id: snapshot.id,
                });
            });
            subtasks.sort((a, b) => a.sort - b.sort);

            // nest structure
            subtasks.forEach((s) => {
                const task = tasks.find(
                    (t) => t.id === this.$options.filters.fireRef2id(s.task)
                );
                if (task) task.subtasks.push(s);
            });
            tasks.forEach((t) => {
                const group = groups.find(
                    (g) => g.id === this.$options.filters.fireRef2id(t.group)
                );
                if (group) group.tasks.push(t);
            });
            estimate.groups = groups;

            return estimate;
        },

        /** get nested group data from a nested estimate */
        getNestedGroup(estimate, groupId) {
            if (estimate?.groups) {
                return estimate.groups.find((g) => g.id === groupId);
            }
            return null;
        },
    },
};

export default estimateMixin;
